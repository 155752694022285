<template>
  <div class="d-flex flex-column">
    <alert v-if="loading" />
    <div v-else>
      <div class="card">
        <div class="d-flex justify-content-between align-items-center p-4">
          <h2 class="m-0">
            {{ type.displayText }}
          </h2>
          <save
            classes="btn btn-theme text-uppercase m-0"
            :disabled="!modifiedPermissions.length || saving"
            :saving="saving"
            @click="sendPermissions"
          >
            Save Changes
          </save>
        </div>
        <div class="card-body pb-0" v-if="!permissions.length">
          <alert :hideLoader="true"> No {{ type.displayText }}s </alert>
        </div>
        <div class="overflow-auto" v-if="permissions.length">
          <table class="table table-sm table-striped mb-0 table-custom">
            <thead v-if="!mobileView">
              <tr>
                <th>Field/Area</th>
                <th v-for="group in groups" :key="group.id">
                  <p class="ml-2 mb-0">{{ group.name }}</p>
                </th>
              </tr>
            </thead>
            <tbody v-if="mobileView" class="permissionsList-mobileView">
              <tr v-for="permission in permissions" :key="permission.id">
                <td>
                  <div class="permissionsList__name">
                    <label>Field/Area:</label>
                    <div id="permissionsList__content">
                      {{ capitalizeFirstLetter(permission.name) }}
                    </div>
                  </div>
                  <div class="permissionList__accessType">
                    <label>Access types per group:</label>
                    <div class="permissionList-content">
                      <label
                        v-for="(grp, index) in permission.groupPermission"
                        :key="index"
                      >
                        <span> {{ groups[index].name.toUpperCase() }}: </span>
                        <select
                          class="form-control"
                          v-model="grp.access_type"
                          :options="accessTypeOptions"
                          @change="storeChanges(permission)"
                        >
                          <option
                            v-for="(accessTypeOpt, index) in accessTypeOptions"
                            :value="accessTypeOpt.value"
                            :key="index"
                          >
                            {{ accessTypeOpt.label }}
                          </option>
                        </select>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="permission in permissions" :key="permission.id">
                <td>
                  <div>{{ capitalizeFirstLetter(permission.name) }}</div>
                </td>
                <td
                  v-for="(grp, index) in permission.groupPermission"
                  :key="index"
                >
                  <select
                    class="form-control"
                    v-model="grp.access_type"
                    :options="accessTypeOptions"
                    @change="storeChanges(permission)"
                  >
                    <option
                      v-for="(accessTypeOpt, index) in accessTypeOptions"
                      :value="accessTypeOpt.value"
                      :key="index"
                    >
                      {{ accessTypeOpt.label }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["type"],
  data() {
    return {
      modifiedPermissions: [],
      accessTypeOptions: [
        { value: 0, label: "No Access" },
        { value: 1, label: "View" },
        { value: 2, label: "Edit" },
        { value: 3, label: "Create" },
        { value: 4, label: "Delete" },
        { value: 5, label: "All" },
      ],
      mobileView: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 1100) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
  },
  watch: {
    type: function (newVal) {
      let vm = this;
      this.getGroups(newVal.id).then(() => {
        const lastElement = vm.groups.length - 1;
        vm.groups[
          lastElement
        ].name = `${vm.groups[lastElement].name} ${vm.type.displayText}`;
      });
      this.getPermissions(newVal.id);
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    ...mapState({
      loading: (state) => state.permissions.permissions.loading,
      saving: (state) => state.permissions.permissions.saving,
      permissions: (state) => state.permissions.permissions.data,
      groups: (state) => state.permissions.groups,
    }),
  },
  methods: {
    ...mapActions({
      getPermissions: "permissions/getPermissions",
      updatePermissions: "permissions/updatePermissions",
      getGroups: "permissions/getGroups",
    }),
    storeChanges: function (permission) {
      if (!this.modifiedPermissions.includes(permission.id))
        this.modifiedPermissions.push(permission.id);
    },
    sendPermissions: function () {
      const changedPermissions = this.permissions.filter((permission) => {
        return this.modifiedPermissions.includes(permission.id);
      });
      this.updatePermissions({
        changedPermissions,
        role_id: this.type.id,
      }).then((result) => {
        if (!result) {
          this.getPermissions(this.type.id);
        }
        this.modifiedPermissions = [];
      });
    },
    myEventHandler(e) {
      if (e.target.innerWidth <= 1100) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    capitalizeFirstLetter: function (data) {
      const words = data.split(" ");
      return words
        .map((word) => {
          return word[0].toUpperCase() + word.substring(1);
        })
        .join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.permissionsList-mobileView {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #e7efff;
  margin-top: 4px;
  td {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    box-shadow: #062157 0px 0px 5px 0px;
  }
  .permissionsList__name {
    display: flex;
    align-items: center;
    gap: 10px;
    label {
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .permissionList__accessType {
    display: flex;
    gap: 10px;
    > label {
      font-size: 13px;
      font-weight: 600;
      flex-basis: 20%;
    }
    .permissionList-content {
      flex: 1;
      font-size: 13px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      place-items: center stretch;
      gap: 10px;
      label {
        display: flex;
        gap: 5px;
        > span {
          margin-top: 4px;
        }
      }
    }
  }
}
.form-control {
  font-size: 13px !important;
}
</style>
