<template>
  <div class="container">
    <div class="ml-2 description">
      <h1>Permissions</h1>
      <p>
        For each user role (admin, managing admin, client, provider), you can
        set the access level (No Access, View, Edit, Create, Delete, All) for
        each access group (executive, director, general user, scheduler,
        billing, payroll, default) for each field/area in the system. Then in
        the
        <router-link :to="{ name: 'users-roles' }">Users Groups</router-link>
        page, you can select the suitable access group for each user in the
        system.
        <br />
        <br />
        <strong>
          Use the left navigation to switch between different user roles.
        </strong>
      </p>
    </div>
    <br />
    <div class="user-groups-container">
      <DrawerMenu header="Permissions">
        <template v-slot:body="slotsProps">
          <ul class="nav-item-list">
            <li
              v-for="(section, index) in sideBarSections"
              :key="index"
              :class="
                activeSection.id === section.id ? 'nav-item active' : 'nav-item'
              "
              @click="setActive({ section, slotsProps })"
            >
              {{ section.displayText }}
            </li>
          </ul>
        </template>
      </DrawerMenu>
      <PermissionsList :type="activeSection" class="permissions-content" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import PermissionsList from "./PermissionsList.vue";
import DrawerMenu from "../../../../components/DrawerMenu.vue";
export default {
  name: "Permissions",
  components: {
    PermissionsList,
    DrawerMenu,
  },
  computed: {
    ...mapState({
      sideBarSections: (state) => state.permissions.sideBarSections,
    }),
  },
  data() {
    return {
      activeSection: {},
    };
  },
  mounted() {
    this.activeSection = this.sideBarSections[0];
  },
  methods: {
    setActive(data) {
      this.activeSection = data.section;
      data.slotsProps.toggle();
    },
  },
};
</script>

<style scoped lang="scss">
.user-groups-container {
  position: relative;
  display: flex;
  gap: 10px;
}
.description {
  > p {
    background: white;
    padding: 14px 24px;
    border-radius: 0.5rem;
    a {
      color: #2c7be5;
      text-decoration: underline;
      background-color: transparent;
    }
  }
}
.permissions-content {
  overflow: auto;
  width: 100%;
}
.nav-item-list {
  list-style: none;
  padding: 0;
}
.nav-item {
  min-height: 24px;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  padding: 12px 20px !important;
  transition: 0.1s;
}
.nav-item:hover {
  color: #ffffff50;
  transition: 0.1s;
}
.nav-item.active {
  background-color: rgba(255, 255, 255, 0.12);
}
</style>
